<template>
  <article>
    <!-- Início principal -->
    <v-toolbar flat>
      <v-icon>fas fa-tag</v-icon>
      <v-toolbar-title class="pl-4"> Objetos Em Andamento </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <Data :title="$route.name" :dataInicio="camposDatas.dataInicial" :dataFinal="camposDatas.dataFinal" :indicadores="indicadores" />
    <!-- Fim principal -->
  </article>
</template>

<script>
import moment from "moment";
import Template from "../../components/Template.vue";
import Data from "./frmData.vue";

export default {
  name: "Pendentes",
  components: {
    Template,
    Data
  },
  data: () => ({
    indicadores: false,
    camposDatas: {
      dataInicial: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      dataFinal: moment(new Date()).format('YYYY-MM-DD'),
    },

    grid: {
      objetos: {
        cabecalho: [
          { text: "Cod Pedido", value: "numero_pedido", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Serviço", value: "cod_servico", sortable: false },
          { text: "Nota Fiscal", value: "nota_fiscal", sortable: false },
          { text: "Prazo", value: "prazo_entrega", sortable: false },
          { text: "Data Venda", value: "data_venda", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Considerada", value: "data_considerada", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Prevista", value: "data_prevista", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Entrega", value: "data_entrega", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Movimentação", value: "data_ultima_posicao", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Movimentação", value: "ultima_posicao", sortable: false },
          { text: "Reclamado", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 50,
        itemSelecionado: [],
        coluna: "objetos.cod_empresa",
        direcao: "ASC",
        ordenacao: {
          colunas: [],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
      name: "Pendentes",
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      numero_rastreio: "",
      prazo: "",
      dt_considerada: "",
      dt_prevista: "",
      dt_entrega: "",
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao: "",
      ultima_posicao: "",
    },
    objetoAtual: "",
    numero_nfe: "",
    filtro: false,
    filtroFechar: false,
    rastreios: [],
    detalhes: [],
    loading: false,
    objeto: {
      aba_rastreio: true,
    },
    detmodal: false,
  }),
  
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.numero_rastreio = "";
      this.filtros.prazo = "";
      this.filtros.dt_considerada = "";
      this.filtros.dt_prevista = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao = "";
      this.filtros.ultima_posicao = "";
    },
    fechar() {
      this.$router.push("/");
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },
    routeLoaded(){
      let data_inicio = this.$route.params.data_inicio;
      let data_fim = this.$route.params.data_fim;
      this.indicadores = true;
      if(data_inicio == '' && data_fim == ''){
        data_inicio = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
        data_fim = moment(new Date()).format('YYYY-MM-DD');
      }
      this.camposDatas.dataInicial = data_inicio
      this.camposDatas.dataFinal = data_fim;
    }
  },

  async created() {
    if(this.$route.params.data_fim != null)
      this.routeLoaded();
  },

  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 50;
            this.mudarPagina(1);
          }
        }
      },
    },
    "objeto.numero_pedido": {
      handler: function (val) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.cod_empresa = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },
};
</script>